import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Type from 'components/type';

import { Container, Alert } from './page-wrapper.css';

const PageWrapper = ({ children, bg, withAlert, showAlert, alertText }) => (
  <Container
    bg={bg}
    style={{
      height: 'auto !important',
    }}
  >
    {children}
    {withAlert && showAlert &&
      <Alert bg={bg}>
        <Type>
        {documentToReactComponents(
          alertText.json
        )}
        </Type>
      </Alert>
    }
  </Container>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
};


const PageWrapperWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulSplashPage {
          id
          title
          showAlert
          alertText {
            json
          }
        }
      }
    `}
    render={data => (
      <PageWrapper {...data.contentfulSplashPage} {...props} />
    )}
  />
);

export default PageWrapperWithQuery;
