import nanostyled from 'nanostyled';

export const Container = nanostyled('div', {
  s: null,
  bg: null,
  base: 'flex min-h-screen w-screen max-w-full relative',
});


export const Alert = nanostyled('div', {
  s: null,
  bg: null,
  base: 'alert absolute mt-9 sm:mt-14 mx-9 sm:mx-14 border-b border-black border-solid bg-white p-2 text-center top-0 left-0 right-0',
});
