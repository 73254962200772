import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './box.css';

const Box = ({ children, width, flex, span, override }) => (
  <Container width={width} flex={flex} span={span} override={override}>
    {children}
  </Container>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  flex: PropTypes.string,
  span: PropTypes.string,
};

export default Box;
